import React from "react";

const Caixa = ({value, name ,ao, colors}) => {
    return (
      <div className="Caixa" style={{background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
        <div>{(Math.round(value*10)/10).toString().replace('.', ',')}</div>
        <span><b>{name}</b><br/>médi{ao} realizad{ao} dia</span>
      </div>
    );
};

export default Caixa;
