import React, { useState } from "react";
import Filter from "./Filter";
import env from "../../../env";

const Filters = ({data, go}) => {
    const [open, setOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const clear = (e) => {

    }
    const change = (d,i,on) => {
      let f = {...filters};
      let v = data[d][i].valor;
      f[d] = f[d] || [];
      let n = f[d].indexOf(v);
      if(n == -1 && on){
        f[d].push(v);
      }
      if(!on && n > -1){
        f[d].splice(n, 1);
      }
      if(f[d].length == 0) delete f[d];
      setFilters(f);
      // console.log(f);
    }
    const filtrar = () => {
      setOpen(false);
      go(filters);
    }
    return (
      <div>
        <a className="showFilter" onClick={()=>{setOpen(!open)}}/>
        <div className={open?"Filters":"Filters hide"}>
          <h4>Filtrar por: <a onClick={clear}>Limpar filtros</a></h4>
          {data?.agencia?.length > 0?<Filter data={data.agencia} name="agencia" label="Agencia" change={change} filters={filters.agencia||[]}/>:null}
          {data?.lu?.length > 0?<Filter data={data.lu} name="lu" label="LU" change={change} filters={filters.lu||[]}/>:null}
          {data?.consultor?.length > 0?<Filter data={data.consultor} name="consultor" label="Consultor" change={change} filters={filters.consultor||[]}/>:null}
          {data?.treinamento?.length > 0?<Filter data={data.treinamento} name="treinamento" label="Treinamento" change={change} filters={filters.treinamento||[]}/>:null}
          {data?.ano?.length > 0?<Filter data={data.ano} name="ano" label="Ano" change={change} filters={filters.ano||[]}/>:null}
          <button className="FilterButton" onClick={filtrar}>Filtrar</button>
        </div>
      </div>
    );
};

export default Filters;
