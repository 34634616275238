import React from "react";

const Message = ({msg}) => {
    const style = {
      width: '100%',
      height: '100px',
      fontWeight: 700,
      fontSize: 'max(min(1.6vw, 24px), 12px)',
      lineHeight: '1.5em',
      textAlign: 'center',
      color: '#fff',
      background: '#22272E',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      margin: '20px 0',
      padding: '0 1.2em',
    };
    return (<div style={style}>{msg}</div>);
};

export default Message;
