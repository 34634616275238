import React from "react";
import "./parts/loading.css";
import loadImg from "./parts/loading.svg";
const Loading = ({msgs}) => {

    return (
      <div>
        <div className="Loading">
          <span>{msgs[0]}</span>
          <img src={loadImg}/>
          <span>{msgs[1]}</span>
        </div>
      </div>
    );
};

export default Loading;
