import React from "react";

const Next = ({pag, tot, onClick}) => {
    const style = {
      div: {
        height: '50px',
        borderRadius: '25px',
        background: '#1F2328',
        color: '#515D6D',
        width: '270px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '35px 0 15px',
      },
      span: {
        textAlign: 'center',
        flex: 1,
        fontSize: '18px',
        lineHeight: '22.77px',
        fontWeight: '400',
      },
      button: {
        width: '200px',
        height: '50px',
        borderRadius: '25px',
        background: "#0090DA url(\"data:image/svg+xml,%3Csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM0 9H16V7H0V9Z' fill='black'/%3E%3C/svg%3E%0A\") no-repeat",
        backgroundPosition: "160px 50%",
        border: 0,
        fontSize: '18px',
        lineHeight: '22.77px',
        fontWeight: 'bold',
        textAlign: 'left',
        padding: '0 25px',
      }
    }
    return (
      <div style={style.div}>
        <span style={style.span}>{pag}/{tot}</span>
        <button style={style.button} onClick={onClick}>Próxima etapa</button>
      </div>
    );
};

export default Next;
