import React, { useState, useEffect } from "react";
import Next from "./parts/Next";
import Pergunta from "./parts/Pergunta";
import Message from "./parts/Message";
import "./parts/page5.css";

const Page = ({user, save, send, data}) => {
    const saveData = (e) => {
      let tgt = e.current?.target || e.target || e;
      let newdata = {...data, [tgt.name]: tgt.value};
      save(newdata);
    }
    const aceite = (e) => {
      let newdata = {...data, aceite: data.aceite?0:1};
      save(newdata);
    }
    const nextPage = () => {
      if(data.aceite) send();
    }
    return (
      <div style={{textAlign: 'left'}}>
        <Message msg={<div style={{textAlign:'center', width:'100%'}}>Você precisa autorizar a utilização das informações<br/> para acompanhamento das vendas de produtos Metlife</div>}/>
        <label className="container">
          <input type="checkbox" name='aceite' value="1" onChange={aceite} checked={data.aceite==1}/>
          <span className="checkmark" />
          Autorizo a utilização dessas informações respondidas acima
        </label>
        <button className="btn-send" onClick={nextPage}>Enviar</button>
      </div>
    );
};

export default Page;
