import React, { useState, useEffect } from "react";
import Next from "./parts/Next";
import Pergunta from "./parts/Pergunta";

const Page = ({user, save, go, data}) => {
    const saveData = (e) => {
      let tgt = e.current?.target || e.target || e;
      let newdata = {...data, [tgt.name]: tgt.value};
      save(newdata);
    }
    const nextPage = () => {
      let fields = ['fechamentos_lu_presencial','fechamentos_lu_virtual','propostas_fechadas_presencial','propostas_fechadas_virtual','recomendacoes_obtidas'];
      for(let i of fields){
        if(typeof data[i] === 'undefined' || data[i] === "") return;
      }
      go(5);
    }
    return (
      <div>
        <Pergunta change={saveData} name="fechamentos_lu_presencial" label={<div>FECHAMENTOS COM O ACOMPANHAMENTO<br/>DO L.U. (PRESENCIAL)</div>} value={data.fechamentos_lu_presencial} />
        <Pergunta change={saveData} name="fechamentos_lu_virtual" label={<div>FECHAMENTOS COM O ACOMPANHAMENTO<br/>DO L.U. (VIRTUAL)</div>} value={data.fechamentos_lu_virtual} />
        <Pergunta change={saveData} name="propostas_fechadas_presencial" label={<div>PROPOSTAS FECHADAS (PRESENCIAL)</div>} value={data.propostas_fechadas_presencial} />
        <Pergunta change={saveData} name="propostas_fechadas_virtual" label={<div>PROPOSTAS FECHADAS (VIRTUAL)</div>} value={data.propostas_fechadas_virtual} />
        <Pergunta change={saveData} name="recomendacoes_obtidas" label={<div>RECOMEDAÇÕES OBTIDAS</div>} value={data.recomendacoes_obtidas} />
        <Next pag="4" tot="4" onClick={nextPage}/>
      </div>
    );
};

export default Page;
