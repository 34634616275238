import styled from "styled-components"

const Login = styled.main`
    position: relative;

    min-height: calc(100vh - 70px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 20px;

    >h2{
      color: #FFF;
      font-family: Arial;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }
`;
export default Login;
