import React from "react";
import GraphBox from "./GraphBox"

const Graph = ({boxed, data, title, colors}) => {
  let aux = [];
  for(let i in data){
    aux.push(data[i]);
  }
  const max = Math.max.apply(null, aux);
  const boxes = (
    <div className="boxes">
      <GraphBox value={(Math.round((data?.fe_realizado/data?.ab_realizada)*10000)/100)} text="AB R. x FE R." colors={colors}/>
      <GraphBox value={(Math.round((data?.propostas/data?.fe_realizado)*10000)/100)} text="FE R. x PRO." colors={colors}/>
    </div>
  );
  return (
    <div>
      <h3>{title}</h3>
      <div className="Graph">
        <div className={boxed?"boxed funil":"funil"}>
          <div className="labels">
            {!boxed?<div>AB marcada</div>:''}
            <div>AB realizada</div>
            {!boxed?<div>AB marcada</div>:''}
            <div>AB realizada</div>
            <div>Propostas</div>
          </div>
          <div className="lines">
            {!boxed?<div style={{width: (data?.ab_marcada/max)*100+'%', background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
              <span>{data?.ab_marcada}</span>
            </div>:''}
            <div style={{width: (data?.ab_realizada/max)*100+'%', background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
              <span>{data?.ab_realizada}</span>
            </div>
            {!boxed?<div style={{width: (data?.fe_marcado/max)*100+'%', background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
              <span>{data?.fe_marcado}</span>
            </div>:''}
            <div style={{width: (data?.fe_realizado/max)*100+'%', background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
              <span>{data?.fe_realizado}</span>
            </div>
            <div style={{width: (data?.propostas/max)*100+'%', background:"linear-gradient(90deg, "+colors[0]+" 0%, "+colors[1]+" 100%)"}}>
              <span>{data?.propostas}</span>
            </div>
          </div>
        </div>
        {boxed?boxes:''}
      </div>
    </div>
  );
};

export default Graph;
