import React from "react";

const GraphBox = ({value, text, colors}) => {
    return (
      <div className="GraphBox">
        <div style={{color:colors[0]}}>
          {value.toString().replace('.', ',')}%
        </div>
        <span>{text}</span>
      </div>
    );
};

export default GraphBox;
