import styled from "styled-components"

const InputText = styled.input`
    width: 370px;
    height: 55px;
    left: 775px;
    top: 307px;

    background: #22272E;
    border-radius: 10px;

    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;

    color: #D6E1EF;
    border:none;

    padding: 0 10px;
`;

export default InputText;
