import React, { useState } from "react";

const Filter = ({data, name, label, active, change, filters}) => {
    const [open, setOpen] = useState(false);
    let itens = [];
    for(let i in data){
      itens.push(<div key={"item_"+name+i} className={"FilterItem"+(filters.indexOf(data[i].valor) > -1?' sel':'')} onClick={()=>(click(i))}>{data[i].texto}</div>)
    }
    const clear = (e) => {

    }
    const click = (i) => {
      let v = data[i].valor;
      let n = filters.indexOf(v);
      if(n == -1){
        change(name, i, true);
      }else{
        change(name, i, false);
      }
    }
    return (
      <div className="Filter">
        <label onClick={e=>{setOpen(!open)}}>{label}</label>
        <div className={open?"items":"items hide"}>
          {itens}
        </div>
      </div>
    );
};

export default Filter;
