import React, { useState } from "react";
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Loading from './Loading';
import Dashboard from './Dashboard';
import env from '../../env';

const Atividades = ({user, logout}) => {
  const [data, setData] = useState({email:user.email||''});
  const [loadMsg, setLoadMsg] = useState(["A sua resposta foi registrada.", "Gerando dashboard, aguarde."]);
  const [page, setPage] = useState(1);
  const [graph, setGraph] = useState({});
  const saveData = (d) => {
    setData({...d});
  }
  const nextPage = (go) => {
    // console.log(data);
    setPage(go);
  }
  const sendData = () => {
    setPage('loading');
    // console.log(data);
    let formData = new FormData();
    formData.append('user', user.id);
    formData.append('token', user.auth_token);
    for(let i in data){
      if(i == 'data_evento'){
        data[i] = (new Date(data[i])).toISOString().split('T')[0] + ' 00:00:00';
      }
      formData.append(i, data[i]);
    }
    let options = {
      method: 'POST',
      mode: 'cors',
      cache: 'default',
      body: formData
    };
    fetch(env.api + "/experts/link_atividades", options)
      .then(res => res.json())
      .then(
        (result) => {
          // console.log(result);
          if(result.response){
            getData();
          }else{
            setPage(6);
          }
        },
        (error) => {
          console.error(error);
          setPage(6);
        }
    )
  }
  const getData = (filters) => {
    setPage('loading');
    let formData = new FormData();
    formData.append('user', user.id);
    formData.append('token', user.auth_token);
    if(filters){
      for(var i in filters){
        formData.append(i+'[]', filters[i]);
      }
    }
    let options = {
        method: 'POST',
        mode: 'cors',
        cache: 'default',
        body: formData
    };
    fetch(env.api + "/experts/link_atividades/grafico", options)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.response){
            setPage('dashboard');
            setGraph(result);
          }else{
            // console.log(result);
            setLoadMsg(['Erro ao gerar dashboard!', 'Recarregue a página'])
          }
        },
        (error) => {
          console.error(error);
          setLoadMsg(['Erro ao gerar dashboard!', 'Recarregue a página'])
        }
    )
  }
  const selPage = () => {
    switch(page){
      case 1:
        return (<Page1 user={user} save={saveData} data={data} go={nextPage}/>)
        break;
      case 2:
        return (<Page2 save={saveData} data={data} go={nextPage}/>)
        break;
      case 3:
        return (<Page3 save={saveData} data={data} go={nextPage}/>)
        break;
      case 4:
        return (<Page4 save={saveData} data={data} go={nextPage}/>)
        break;
      case 5:
        return (<Page5 save={saveData} data={data} send={sendData}/>)
        break;
      case 'loading':
        return (<Loading msgs={loadMsg}/>)
        break;
      default:
        return (<Dashboard user={user} getData={getData} data={graph}/>)
        break;
    };
  }

  return (
    <section>
      {selPage()}
    </section>
  )
};

export default Atividades;
