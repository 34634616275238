import React from "react"
import Content from "./Content";
import InputText from "./Input";
import Label from "./Label";

const Text = ({label, type, name, register, ...props}) => (
    <Label>
      <Content>{label}</Content>
      <InputText type={type} name={name} ref={register} {...props}/>
    </Label>
);

export default {
    Text,
};
