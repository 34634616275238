import React from 'react';
import Calendar from '../Calendar';

function Pergunta(props) {
  const style = {
    pergunta: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '1.265em',
      color: '#fff',
      textAlign: 'left',
      margin: '15px 0 10px',
    },
    input: {
      default: {
        height: '50px',
        width: '100%',
        background: '#22272E',
        borderRadius: '10px',
        color: '#fff',
        fontSize: '16px',
        lineHeight: '5em',
        border: 0,
        margin: '5px 0 15px',
      },
      text: {
        width: '100%',
        padding: '0 30px',
        textAlign: 'left',
      },
      number: {
        width: '100px',
        padding: 0,
        textAlign: 'center',
      }
    }
  }
  const change = (e) => {
    props.change(e);
  }
  let width = (props.cols && props.cols > 1) ? 'calc('+(100/props.cols)+'% - 15px)' : '100%'
  const makeInput = () => {
    let input = null;
    if(props.type == 'date') {
      input = <Calendar user={props.user} onChange={change} value={props.value} />
    }else{
      input = <input style={{...style.input.default, ...style.input.number, ...style.input[props.type]}} name={props.name} type={props.type||'number'} value={props.value||""} onChange={change} readOnly={props.readonly} min="0"/>
    }
    return input;
  }
  return (
    <div style={style.pergunta} className="Pergunta">
      <label style={style.label}>{props.label}</label>
      {makeInput()}
    </div>
  );
}

export default Pergunta;
