import React, { useState, useEffect } from "react";
import Next from "./parts/Next";
import Pergunta from "./parts/Pergunta";

const Page = ({user, save, go, data}) => {
    const saveData = (e) => {
      let tgt = e.current?.target || e.target || e;
      let newdata = {...data, [tgt.name]: tgt.value};
      // console.log({[tgt.name]: tgt.value})
      save(newdata);
    }
    const nextPage = () => {
      let fields = ['fechamentos_agendados','fechamentos_realizados_presencial','fechamentos_realizados_virtual','fechamentos_realizados_2_presencial','fechamentos_realizados_2_virtual','fechamentos_realizados_3_presencial','fechamentos_realizados_3_virtual'];
      for(let i of fields){
        if(typeof data[i] === 'undefined' || data[i] === "") return;
      }
      go(4);
    }
    return (
      <div>
        <Pergunta change={saveData} name="fechamentos_agendados" label={<div>FECHAMENTOS AGENDADOS<br/>Com visitas marcadas para hoje</div>} value={data.fechamentos_agendados} />
        <Pergunta change={saveData} name="fechamentos_realizados_presencial" label={<div>FECHAMENTOS REALIZADOS (PRESENCIAL)<br/>Em caso de delay não deve ser considerado</div>} value={data.fechamentos_realizados_presencial} />
        <Pergunta change={saveData} name="fechamentos_realizados_virtual" label={<div>FECHAMENTOS REALIZADOS (VIRTUAL)<br/>Em caso de delay não deve ser considerado</div>} value={data.fechamentos_realizados_virtual} />
        <Pergunta change={saveData} name="fechamentos_realizados_2_presencial" label={<div>FECHAMENTOS REALIZADOS 2 (PRESENCIAL)<br/>Em caso de delay, não deve ser considerado</div>} value={data.fechamentos_realizados_2_presencial} />
        <Pergunta change={saveData} name="fechamentos_realizados_2_virtual" label={<div>FECHAMENTOS REALIZADOS 2 (VIRTUAL)<br/>Em caso de delay, não deve ser considerado</div>} value={data.fechamentos_realizados_2_virtual} />
        <Pergunta change={saveData} name="fechamentos_realizados_3_presencial" label={<div>FECHAMENTOS REALIZADOS 3 (PRESENCIAL)<br/>Em caso de delay, não deve ser considerado</div>} value={data.fechamentos_realizados_3_presencial} />
        <Pergunta change={saveData} name="fechamentos_realizados_3_virtual" label={<div>FECHAMENTOS REALIZADOS 3 (VIRTUAL)<br/>Em caso de delay, não deve ser considerado</div>} value={data.fechamentos_realizados_3_virtual} />
        <Next pag="3" tot="4" onClick={nextPage}/>
      </div>
    );
};

export default Page;
