import React from "react";

const Dia = ({dia, selected, enable, active, hasReg, select}) => {
    const onClick = (e) => {
      if(enable && !selected) select(dia, hasReg);
    }
    const makeStyle = () => {
      let color = '#fff';
      if (enable) {
        if(hasReg && active){
          color = "rgb(150, 255, 150, 255)";
        }else if (hasReg){
          color = "rgb(119, 224, 119, 255)";
        }else if (active){
          color = "rgb(208, 208, 208, 255)";
        }else{
          color = "rgb(119, 119, 119, 255)";
        }
      }else{
        if (active){
          color = "rgb(88, 88, 88, 255)";
        }else{
          color = "rgb(69, 69, 69, 255)";
        }
      }
      return {
        color: color,
        cursor: enable?'pointer':'',
      }
    }

    return (<span onClick={onClick} className={(selected?'sel':'')+' '+(enable?'enable':'')} style={makeStyle()}>{dia.getDate()}</span>);
};

export default Dia;
