import styled, { css } from "styled-components"

const Label = styled.label`

    font-family: Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;

    color: #D6E1EF;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    [type="number"]::-webkit-inner-spin-button{
      -webkit-appearance: none;
    }
    ${props => props.error && css `
      margin: 0 auto;
      font-size: 0.9em;
      color: #F00;
      min-height: 2em;
    `}
`;

export default Label;
