import React from "react";

const Media = ({data, mes}) => {
    const capitalize = (s) => {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    return (
      <div className="Media">
          <div className="small">
            <div>Media</div>
            <div>em {data?capitalize((new Date(data[mes]?.mes+'-02')).toLocaleString('pt-BR', {month:"long", year:"numeric"})):''}</div>
          </div>
          <hr/>
          <div className="big">
            {data?(Math.round(data[mes]?.valor*10)/10).toString().replace(".", ","):''}
          </div>
      </div>
    );
};

export default Media;
