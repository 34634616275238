import styled from "styled-components"

const StyledButton = styled ("button") `
    font-weight: bold;
    font-size: 18px;
    line-height: 23px;
    color: #16191D;
    padding: 14px 59px; 
    background: #0090DA;
    border-radius: 25px;
    border: none;
`
export {StyledButton}