import React from "react";
import CaixaRoll from "./parts/CaixaRoll";
import Caixa from "./parts/Caixa";
import Graph from "./parts/Graph";
import Visitas from "./parts/Visitas";
import Filters from "./parts/Filters";
import "./parts/dashboard.css";

const Dashboard = ({user, getData, data}) => {
    return (
      <div className="Dashboard">
        <Filters data={data.filters} go={getData} />
        <h3>Visão Geral</h3>
        <CaixaRoll>
          <Caixa value={data.grafico?.abordagens_dia} name="Abordagem" ao="a" colors={['#0090DA', '#0071DA']}/>
          <Caixa value={data.grafico?.fechamentos_dia} name="Fechamento" ao="o" colors={['#FFC600', '#FFA200']}/>
          <Caixa value={data.grafico?.propostas_dia} name="Proposta" ao="a" colors={['#A4CE4E', '#8BAF41']}/>
          <Caixa value={data.grafico?.visitas_dia} name="Visita" ao="a" colors={['#BABABA', '#A4A4A4']}/>
        </CaixaRoll>
        <div className="graphs">
          <Graph boxed={false} data={data.grafico?.geral} title="Geral" colors={['#0090DA', '#0071DA']}/>
          <Graph boxed={true} data={data.grafico?.presencial} title="Presencial" colors={['#FFC600', '#FFA200']}/>
          <Graph boxed={true} data={data.grafico?.digital} title="Virtual" colors={['#A4CE4E', '#8BAF41']}/>
          <Visitas data={data.grafico?.mensal}/>
        </div>
      </div>
    );
};

export default Dashboard;
