import React from "react";

const CaixaRoll = (props) => {
    return (
      <div className="CaixaRoll">
        <div>
          {props.children}
        </div>
      </div>
    );
};

export default CaixaRoll;
