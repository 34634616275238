import React, { useState, useEffect } from "react";
import Next from "./parts/Next";
import Message from "./parts/Message";
import Pergunta from "./parts/Pergunta";
import env from "../../env";

const Page = ({user, save, go, data}) => {
    const saveData = (e) => {
      let tgt = e.current?.target || e.target || e;
      let newdata = {...data, [tgt.name]: tgt.value};
      if(tgt.name == 'data_evento'){
        if(tgt.hasReg){
          return getDayData(tgt.value, newdata);
        }else{
          newdata = {email: user.email, data_evento: tgt.value};
        }
      }
      save(newdata);
    };
    const getDayData = (dia, newdata) => {
      let url = env.api + "/experts/link_atividades/dias/"+dia.getFullYear()+"/"+((parseInt(dia.getMonth())+1).toString().padStart(2, '0'))+"/"+(dia.getDate().toString().padStart(2, '0'));
      let formData = new FormData();
      formData.append('user', user.id);
      formData.append('token', user.auth_token);
      let options = {
          method: 'POST',
          mode: 'cors',
          cache: 'default',
          body: formData
      };
      fetch(url, options)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.response){
              let atv = {...result.atv};
              delete atv.created_at;
              delete atv.updated_at;
              delete atv.data_evento;
              delete atv.dia_util;
              delete atv.aceite;
              delete atv.id;
              delete atv.user_id;
              save({...newdata, ...atv});
              // console.log(newdata, atv);
            }else{
              save({email:user.email})
            }
          },
          (error) => {
            console.error(error);
          }
      )
    };
    const nextPage = () => {
      let fields = ['email','data_evento','ligacoes_efetivadas','abordagens_agendadas'];
      for(let i of fields){
        // console.log(i, data[i],data);
        if(typeof data[i] === 'undefined' || data[i] === "") return;
      }
      go(2);
    };
    return (
      <div>
        <Message msg="Essa pesquisa é destinada a todos os corretores parceiros que tenham interesse em acompanhar seus indicadores de conversão de atividades para o desenvolvimento de seu próprio negócio."/>
        <Pergunta change={saveData} name="email" type="text" label="Digite seu e-mail com domínio @parceirosmetlife.com.br:" value={data?.email} readonly={user.email?true:false} />
        <Pergunta change={saveData} name="data_evento" type="date" label="Seleciona a DATA em que a atividade foi REALIZADA:" value={data?.data_evento} user={user}/>
        <Pergunta change={saveData} name="ligacoes_efetivadas" label={<div>LIGAÇÕES EFETIVADAS<br/>Ligações que o cliente atendeu</div>} value={data?.ligacoes_efetivadas} />
        <Pergunta change={saveData} name="abordagens_agendadas" label={<div>ABORDAGENS AGENDADAS<br/>Com visitas marcadas para hoje</div>} value={data?.abordagens_agendadas} />
        <Next pag="1" tot="4" onClick={nextPage}/>
      </div>

    );
};

export default Page;
