import React from "react";

const BarraMes = ({value, i, mes, max, selected, click}) => {
    const onClick = (e) => {
      click(i);
    }
    return (
      <div className="BarraMes" onClick={onClick}>
        <div style={{height: (100*value/max)+'%'}}></div>
        <span style={{color:selected?'rgb(39, 136, 240)':'rgb(210,210,210)'}}>
          {(new Date(mes+'-02')).toLocaleString('pt-BR', {month:"short"}).substr(0,3)}
        </span>
      </div>
    );
};

export default BarraMes;
