import React, { useState, useEffect } from "react";
import Dia from "./Dia";
import env from "../../../env";
import "./style.css";

const Calendar = ({user, onChange, value}) => {
    const formatMonth = (m) => {
      return new Intl.DateTimeFormat('pt-BR', {month: 'long'}).format(m);
    }
    const formatYear = (m) => {
      return new Intl.DateTimeFormat('pt-BR', {year: 'numeric'}).format(m);
    }
    const formatMY = (m) => {
      return new Intl.DateTimeFormat('pt-BR', {month: 'long', year: 'numeric'}).format(m).replace('de ', '');
    }
    const hoje = new Date();
    hoje.setHours(0);
    hoje.setMinutes(0);
    hoje.setSeconds(0);
    hoje.setMilliseconds(0);
    const [dia, setDia] = useState(new Date(value));
    hoje.setDate(1);
    const [mes, setMes] = useState({date: hoje, mes: formatMonth(hoje), ano: formatYear(hoje)});
    const [actDays, setActDays] = useState([]);
    const [last, setLast] = useState({});
    const [iw, setIw] = useState(window.innerWidth);
    useEffect(() => {
      if(window.innerWidth != iw) setIw(window.innerWidth);
      if(mes.ano == last.ano && mes.mes == last.mes) return;
      setLast(mes);
      let url = env.api + "/experts/link_atividades/dias/"+mes.ano+"/"+((parseInt(mes.date.getMonth())+1).toString().padStart(2, '0'));
      let formData = new FormData();
      formData.append('user', user.id);
      formData.append('token', user.auth_token);
      let options = {
          method: 'POST',
          mode: 'cors',
          cache: 'default',
          body: formData
      };
      fetch(url, options)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.response){
              setActDays(result.dias?.map((d)=>d.split(' ')[0]));
            }else{
              setActDays([]);
            }
          },
          (error) => {
            console.error(error);
          }
      )
    })
    const nextMonth = () => {
      let m = mes.date;
      m.setMonth(m.getMonth()+1);
      setMes({date: m, mes: formatMonth(m), ano: formatYear(m)});
    }
    const lastMonth = () => {
      let m = mes.date;
      m.setMonth(m.getMonth()-1);
      setMes({date: m, mes: formatMonth(m), ano: formatYear(m)});
    }
    const selectDay = (day, hasReg) => {
      let dm = day.getMonth();
      let mm = mes.date.getMonth();
      if(dm != mm){
        let m = new Date(day);
        m.setDate(1);
        setMes({date: m, mes: formatMonth(m), ano: formatYear(m)});
      }
      setDia(day);
      onChange({name: 'data_evento', value: day, hasReg: hasReg});
    }
    const renderCal = () => {
      let hoje = new Date();
      hoje = {ano: hoje.getFullYear(), mes: hoje.getMonth(), dia: hoje.getDate()};
      let idate = new Date(mes.date);
      idate.setDate(idate.getDate() - idate.getDay());
      let rows = [];
      for(let r=0; r<6; r++){
        if(idate.getYear() > mes.date.getYear() || idate.getYear() == mes.date.getYear() && idate.getMonth() > mes.date.getMonth()) return rows;
        let dias = []
        for(let c=0; c<7; c++){
          let d = new Date(idate);
          d = {date: d, ano: d.getFullYear(), mes: d.getMonth(), dia: d.getDate()};
          let enable = d.ano < hoje.ano || (d.ano == hoje.ano && d.mes < hoje.mes) || (d.ano == hoje.ano && d.mes == hoje.mes && d.dia <= hoje.dia )
          let active = d.mes == mes.date.getMonth();
          d.mm = (parseInt(d.mes)+1).toString().padStart(2, '0');
          d.dd = parseInt(d.dia).toString().padStart(2, '0');
          let hasReg = actDays.indexOf(d.ano+'-'+d.mm+'-'+d.dd) > -1;
          dias.push(<Dia key={'dia_'+r+'_'+c} dia={d.date} select={selectDay} selected={d.date.getTime() == dia.getTime()} enable={enable} active={active} hasReg={hasReg} />);
          idate.setDate(idate.getDate() + 1);
        }
        rows.push(<div key={'row_'+r} className="dayline">{dias}</div>);
      }
      return rows;
    }

    return (
      <div className="Calendar">
        <div className="line">
          <div style={{transform:'capitalize'}}>{mes.mes} {mes.ano}</div>
          <div>
            <button className="button last" onClick={lastMonth} />
            <button className="button next" onClick={nextMonth} />
          </div>
        </div>
        <div className="dayline">
          <span>D</span>
          <span>S</span>
          <span>T</span>
          <span>Q</span>
          <span>Q</span>
          <span>S</span>
          <span>S</span>
        </div>
        { renderCal() }
      </div>
    );
};

export default Calendar;
