import React, { useState } from "react";
import './App.css';
import LoginForm from './components/LoginForm';
import Login from './components/Login';
import Atividades from './components/Atividades';

function App() {
  const [user, setUser] = useState(null);
  const onLogin = (u) => {
    // console.log(u);
    setUser(u);
  }
  const logOut = () => {
    setUser(null);
  }
  return (
    <div className="App">
      <header />
      { user == null ? (
        <Login>
          <h2>Bem vindo ao Qualtrics versão web</h2>
          <LoginForm onLogin={onLogin} />
        </Login>
      ) : (
        <Atividades user={user} logout={logOut} />
      )}
    </div>
  );
}

export default App;
