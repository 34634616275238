import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../Button";
import Field from "../Field";
import Label from "../Field/Label";
import Form from "../Form";
import env from '../../env';

const LoginForm = ({onLogin}) => {
    const [data, setData] = useState({});
    const {register, handleSubmit, errors} = useForm();
    const onSubmit = (user) => {
        let formData = new FormData();
        for(let i in user){
          formData.append(i, user[i]);
        }
        let options = {
            method: 'POST',
            mode: 'cors',
            cache: 'default',
            body: formData
        };
        // console.log(options);
        fetch(env.api+"/user/check", options)
          .then(res => res.json())
          .then(
            (result) => {
              // console.log(result);
              if(result.response && result.system == 'be_experts' || result.system == 'be_metlife'){
                  setData({logged: true, user: result.user});
                  onLogin(result.user);
              }else if(result.error){
                  if(result.error.indexOf('Senha') > -1){
                    setData({logged:false, error: { senha: result.error }})
                  }else{
                    setData({logged:false, error: { login: result.error }})
                  }
              }else{
                setData({logged:false, error: { login: "Esse usuário não existe nesse sistema" }})
              }
            },
            (error) => {
              console.error(error);
            }
        )
    };
    const maxVal = (e) => {
        let target = e.target || e.target.current;
        if(target.value.length > 14) target.value = target.value.substr(0,14);
    }
    return(
        <Form onSubmit = {handleSubmit(onSubmit)}>
            <Field.Text label="CPF/CNPJ" onChange={maxVal} name="document" type="number" maxLength="14" register={register({pattern: { value: /(^\d{11}$)|(^\d{14}$)/, message: "É necessário digitar um CPF ou CNPJ válidos!"}, required: {value: true, message: "É necessário digitar um CPF ou CNPJ válidos!"}})}/>
            <Label error>{errors.document?.message||data.error?.login}</Label>
            <Field.Text label="Senha" name="password" type="password" register={register({required: {value: true, message: "É necessário digitar uma senha!"}})}/>
            <Label error>{errors.password?.message||data.error?.senha}</Label>
            <br/>
            <Button label = "Enviar"/>
        </Form>
        )
};

export default LoginForm;
