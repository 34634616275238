import React, { useState } from "react";
import DropDown from "./DropDown";
import Media from "./Media";
import BarraMes from "./BarraMes";

const Visitas = ({data}) => {
    const [mes, setMes] = useState(0);
    let max = Math.max.apply(null, data?.map((m)=>m.valor));
    let options = ["Nos últimos 12 meses"];
    const changeMes = (m) => {
      setMes(m);
    }
    const barras = () => {
      let barras = []
      for(let i in data){
        barras.push(<BarraMes key={"barra"+i} value={data[i]?.valor} mes={data[i]?.mes} i={i} max={max} selected={i==mes} click={changeMes} />)
      }
      return barras;
    }
    return (
      <div>
        <h3>Média de visitas realizadas por dia</h3>
        <div className="Graph">
          <div className="visitas-head">
            <DropDown options={options} sel={options[0]}/>
            <Media data={data} mes={mes}/>
          </div>
          <div className="grafico-barras">
              {barras()}
          </div>
        </div>
      </div>
    );
};

export default Visitas;
