import React, { useState } from "react";
import styled from "styled-components";

const DropDownContainer = styled("div") `
  min-width: 230px;
  width: 40%;
  position: relative;
`;

const DropDownHeaderContainer = styled("div") `
  height: 50px;
  border-radius: 10px;
  background: #1F2328 url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 11L0.736859 0.500001L10.2631 0.5L5.5 11Z' fill='%23515D6D'/%3E%3C/svg%3E%0A") no-repeat;
  background-position: calc(100% - 10px) 50%;
  cursor: pointer;
`;

const DropDownHeader = styled("div") `
  height: 50px;
  border-radius: 10px;
  margin-bottom: 0;
  margin-right: 31px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  font-weight: normal;
  font-size: 18px;
  line-height: 50px;
  letter-spacing: 0.3px;
  color: #FFFFFF;
  background: #38404A;
`;

const DropDownListContainer = styled("div") `
  position: absolute;
  width: calc(100% - 15px);
`;

const DropDownList = styled("ul") `
  padding: 0;
  margin: 0;
  padding-left: 0;
  background: #1F2328;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  &:first-child {
    padding-top: 0.4em;
  }
`;

const ListItem = styled("li") `
  list-style: none;
  margin-bottom: 0.4em;
  cursor: pointer;
`;

export default function DropDown({options, sel}) {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(sel);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setIsOpen(false);
    // console.log(selectedOption);
  };

  return (
    <DropDownContainer>
      <DropDownHeaderContainer onClick={toggling}>
        <DropDownHeader>
          {selectedOption || ""}
        </DropDownHeader>
      </DropDownHeaderContainer>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map(option => (
              <ListItem onClick={onOptionClicked(option)} key={Math.random()}>
                {option}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
}
